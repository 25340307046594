import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint, transition } from '@configs/utilities'
import enterpriseStars from '@assets/images/pricing/planCards/enterpriseStars.svg'
import iconAvailable from '@assets/images/pricing/planCards/iconAvailable.svg'
import iconNonAvailable from '@assets/images/pricing/planCards/iconNonAvailable.svg'
import SwitchNoOfIdeas from '@components/pages/pricing/switches/SwitchNoOfIdeas'
import Loader from '@components/ui/loaders/Loader'
import { priceAnimation, priceMonthlyAnimation } from '@configs/animations'
import { OpenModalContext } from '@components/layouts/default/Layout'
import modalContactTypes from '@configs/modalContactTypes'
import { useWindowSize } from '@configs/getGlobalWindowSize'
import AccordionPlanCardPricing from '@components/ui/accordions/AccordionPlanCardPricing'
import iconAi from '@assets/images/pricing/planCards/iconAi.svg'
import { generateAppLink } from '@configs/helpers'
const PlanCard = ({
  intl,
  slug,
  status,
  sticker,
  title,
  description,
  price,
  monthlyPrice,
  currency,
  period,
  buttonText,
  disabledFeatures,
  noi,
  priceOptions,
  onChangeNoi,
  popularCardBackground,
  aiRequests,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allPlanCardFeaturesJson {
        edges {
          node {
            feature
            tooltip
            bold
          }
        }
      }
    }
  `)

  let priceCrossRender = useRef(null)
  let priceCrossLineRender = useRef(null)
  let priceMainRender = useRef(null)
  let priceBilledRender = useRef(null)
  let loaded = price === null ? 'loading' : 'loaded'

  useEffect(() => {
    if (loaded) {
      priceAnimation(priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender)
    }

    period !== 'monthly'
      ? priceAnimation(priceCrossRender, priceCrossLineRender, priceMainRender, priceBilledRender)
      : priceMonthlyAnimation(priceMainRender, priceBilledRender)
  }, [loaded, period])

  const currentWidth = useWindowSize().width

  const getPrice = (period) => {
    return period === 'annual' ? Math.round(price.replace(',', '') / 12) : Math.round(price.replace(',', ''))
  }

  return (
    <Card popularCardBackground={popularCardBackground}>
      <div className={status === 'regular' ? 'cardRegular' : 'cardPopular'}>
        {status === 'popular' && <img src={sticker} className="stickerPopular" alt="sticker" />}
        <TopWrapper>
          <TextWrapper>
            <p className="cardTitle">{title}</p>
            <p className="cardDescription">{description}</p>
          </TextWrapper>

          <PriceWrapper>
            {slug !== 'team' ? (
              <div className="withPriceWrapper">
                <div className="allPriceWrapper">
                  <div className="price">
                    {slug !== 'freePlan' ? (
                      <div className="priceMain">
                        {price === null ? (
                          <Loader />
                        ) : (
                          <div>
                            {period !== 'monthly' && (
                              <div className="crossPrice" ref={priceCrossRender}>
                                <span className={period !== 'lifetime' ? 'dollarLeftCross' : 'dollarCross'}>
                                  {currency}
                                </span>
                                <div className="priceWithoutCurrency">
                                  <div className="number">
                                    {period === 'lifetime' ? getPrice(period) * 9 : Math.trunc(monthlyPrice)}
                                  </div>
                                  <div className="crossLine" ref={priceCrossLineRender}></div>
                                </div>
                              </div>
                            )}
                            {period !== 'monthly' && (
                              <div className="forPriceAnimation" ref={priceMainRender}>
                                <span className={period !== 'lifetime' ? 'dollarLeft' : 'dollar'}>{currency}</span>
                                <span className="priceText">{getPrice(period)}</span>
                              </div>
                            )}
                            {period === 'monthly' && (
                              <div className="forPriceAnimation" ref={priceMainRender}>
                                <span className={period !== 'lifetime' ? 'dollarLeft' : 'dollar'}>{currency}</span>
                                <span className="priceText">{getPrice(period)}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="priceMain">
                        <span className="priceTextFree">{intl.formatMessage({ id: 'pricing.cards.free' })}</span>
                      </div>
                    )}
                  </div>
                  {price !== null && (
                    <Billed ref={priceBilledRender}>
                      {period === 'annual' && (
                        <p className="annual">{intl.formatMessage({ id: 'pricing.cards.billedAnnual' })}</p>
                      )}
                      {period === 'lifetime' && (
                        <p className="lifetime">{intl.formatMessage({ id: 'pricing.cards.billedOneTime' })}</p>
                      )}
                    </Billed>
                  )}
                </div>
                <SwitchPeriodWrapper>
                  {slug !== 'freePlan' ? (
                    <PriceSwitchTextWrapper>
                      <span className="darkText">{intl.formatMessage({ id: 'pricing.cards.switchAdd' })}</span>
                      <span className="blueText">
                        <span className="tooltip">{intl.formatMessage({ id: 'pricing.cards.ideasTooltip' })}</span>
                        {intl.formatMessage({ id: 'pricing.cards.ideas' })}
                      </span>
                      <span className="and">&</span>
                      <span className="blueText">
                        <span className="tooltip">
                          {intl.formatMessage({ id: 'pricing.cards.collaboratorsTooltip' })}
                        </span>
                        {intl.formatMessage({ id: 'pricing.cards.collaborators' })}
                      </span>
                      <SwitchNoOfIdeas
                        key={`${slug}-${period}`}
                        value={noi}
                        priceOptions={priceOptions}
                        onChangeNoi={onChangeNoi}
                      />
                    </PriceSwitchTextWrapper>
                  ) : (
                    <FreePlanIconTextWrapper>
                      <div className="content">
                        <span className="tooltip">{intl.formatMessage({ id: 'pricing.cards.ideasTooltip' })}</span>
                        <FreePlanIcon>1</FreePlanIcon>
                        <FreePlanIconText>{intl.formatMessage({ id: 'pricing.cards.idea' })}</FreePlanIconText>
                      </div>

                      <div className="content">
                        <span className="tooltip">
                          {intl.formatMessage({ id: 'pricing.cards.collaboratorsTooltip' })}
                        </span>
                        <FreePlanIcon>1</FreePlanIcon>
                        <FreePlanIconText>{intl.formatMessage({ id: 'pricing.cards.collaborator' })}</FreePlanIconText>
                      </div>
                    </FreePlanIconTextWrapper>
                  )}
                </SwitchPeriodWrapper>
              </div>
            ) : (
              <EnterpriseStars>
                <img src={enterpriseStars} alt="" />
              </EnterpriseStars>
            )}
          </PriceWrapper>
        </TopWrapper>

        <BottomWrapper>
          <ButtonWrapper>
            {(() => {
              switch (slug) {
                case 'freePlan':
                  return (
                    <a href={generateAppLink('signup', intl.locale)} className="freeButton">
                      {intl.formatMessage({ id: 'buttons.startForFree' })}
                    </a>
                  )
                case 'dreamer':
                case 'founder':
                case 'teamPro':
                  return (
                    <a
                      href={generateAppLink('payment', intl.locale, `plan=${slug}&type=${period}&noi=${noi}`)}
                      className="blueButton"
                    >
                      <span>{buttonText}</span>
                    </a>
                  )

                case 'team':
                  return (
                    <OpenModalContext.Consumer>
                      {(value) => {
                        return (
                          <button
                            onClick={() => {
                              value.openContact(modalContactTypes.ENTERPRISE)
                            }}
                            className="blueButton"
                          >
                            <span>{buttonText}</span>
                          </button>
                        )
                      }}
                    </OpenModalContext.Consumer>
                  )
                default:
                  return null
              }
            })()}
          </ButtonWrapper>
          {currentWidth > 768 ? (
            <FeaturesWrapper>
              <p className="featureTitle">{intl.formatMessage({ id: 'pricing.cards.features.title' })}</p>
              {data.allPlanCardFeaturesJson.edges.map((item, index) => {
                const disabled = disabledFeatures.indexOf(index + 1) === -1
                return (
                  <Feature key={index}>
                    {disabled ? (
                      <div className="enabledFeature">
                        <span className="tooltip">{intl.formatMessage({ id: item.node.tooltip })}</span>
                        <img src={iconAvailable} alt="icon" />
                        <span
                          className={
                            item.node.feature === 'pricing.cards.features.aIRequests'
                              ? 'featureText aiFeature'
                              : 'featureText'
                          }
                        >
                          <span>
                            {slug === 'freePlan' && item.node.feature === 'pricing.cards.features.aIRequests'
                              ? 10
                              : null}
                          </span>
                          <span>{item.node.feature === 'pricing.cards.features.aIRequests' ? aiRequests : null}</span>
                          <span className="featureName">{intl.formatMessage({ id: item.node.feature })}</span>
                        </span>

                        {item.node.feature === 'pricing.cards.features.aIRequests' ? (
                          <div className="aIRequestsContainer">
                            <img src={iconAi} className="aiIcon" alt="ai icon" />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="disabledFeature">
                        <img src={iconNonAvailable} alt="" />
                        <span className="featureText">{intl.formatMessage({ id: item.node.feature })}</span>
                      </div>
                    )}
                  </Feature>
                )
              })}
            </FeaturesWrapper>
          ) : (
            <FeaturesWrapper>
              <AccordionPlanCardPricing
                id="lifetimeCard"
                head={intl.formatMessage({ id: 'pricing.cards.features.title' })}
                text={data.allPlanCardFeaturesJson.edges.map((item, index) => {
                  const disabled = disabledFeatures.indexOf(index + 1) === -1
                  return (
                    <Feature key={index}>
                      {disabled ? (
                        <div className="enabledFeature">
                          <span className="tooltip">{intl.formatMessage({ id: item.node.tooltip })}</span>
                          <img src={iconAvailable} alt="icon" />
                          <span
                            className={
                              item.node.feature === 'pricing.cards.features.aIRequests'
                                ? 'featureText aiFeature'
                                : 'featureText'
                            }
                          >
                            <span>
                              {slug === 'freePlan' && item.node.feature === 'pricing.cards.features.aIRequests'
                                ? 10
                                : null}
                            </span>
                            <span>{item.node.feature === 'pricing.cards.features.aIRequests' ? aiRequests : null}</span>
                            <span className="featureName">{intl.formatMessage({ id: item.node.feature })}</span>
                          </span>
                          {item.node.feature === 'pricing.cards.features.aIRequests' ? (
                            <div className="aIRequestsContainer">
                              <img src={iconAi} className="aiIcon" alt="ai icon" />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="disabledFeature">
                          <img src={iconNonAvailable} alt="" />
                          <span className="featureText">{intl.formatMessage({ id: item.node.feature })}</span>
                        </div>
                      )}
                    </Feature>
                  )
                })}
              />
            </FeaturesWrapper>
          )}
        </BottomWrapper>
      </div>
    </Card>
  )
}

export default injectIntl(PlanCard)

const Card = styled.div`
  user-select: none;
  display: flex;
  margin: 0 auto;
  width: 100%;
  @media screen and (min-width: ${breakpoint.sm}) {
    width: 70%;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    width: 24%;
    max-width: 276px;
  }

  .cardRegular,
  .cardPopular {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 3px solid #bbc9e4;
    border-radius: 8px;
    padding: 30px 20px 30px 25px;
    margin-bottom: 20px;

    @media screen and (min-width: ${breakpoint.md}) {
      padding: 30px 10px 30px 10px;
      margin-bottom: 0;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      padding: 30px 20px 30px 20px;
    }

    .cardDescription {
      min-height: 70px;
    }
  }

  .cardRegular {
    background: ${color.white};
  }

  .cardPopular {
    position: relative;
    background: ${(props) => (props.popularCardBackground ? props.popularCardBackground : 'rgba(138, 177, 255, 0.15)')};

    .stickerPopular {
      position: absolute;
      top: -10px;
      right: 15px;
    }
  }

  .tooltip {
    position: absolute;
    visibility: hidden;
    width: 90%;
    min-width: 177px;
    background: ${color.font};
    color: ${color.white};
    text-align: left;
    border-radius: 8px;
    padding: 10px 15px;
    z-index: 1;
    bottom: 150%;
    left: 0;
    transition: 0.1s all;
    font-weight: 300 !important;
    text-decoration: none !important;
    font-size: 14px !important;
  }
  .tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${color.font} transparent transparent transparent;
  }
`
/* -------------------------------------------------- Card Top -------------------------------------------------- */
const TopWrapper = styled.div``

const TextWrapper = styled.div`
  text-align: center;
  color: ${color.font};
  width: 100%;
  max-width: 220px;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 100%;
  }
  .cardTitle {
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
  }
  .cardDescription {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 13px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 14px;
    }
  }
`

const PriceWrapper = styled.div`
  .withPriceWrapper {
    width: 100%;

    .allPriceWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 140px;

      @media screen and (min-width: ${breakpoint.md}) {
        min-height: 120px;
      }
      @media screen and (min-width: ${breakpoint.xl}) {
        min-height: 140px;
      }

      .price {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${color.blue};

        .crossPrice {
          visibility: hidden;
          display: flex;
          justify-content: center;
          color: #bbc9e4;
          padding-bottom: 12px;
          font-size: 26px;
          line-height: 20px;

          .priceWithoutCurrency {
            position: relative;
            width: fit-content;

            .number {
              width: fit-content;
            }

            .crossLine {
              position: absolute;
              visibility: hidden;
              width: fit-content;
              top: 35%;
              &:after {
                position: absolute;
                content: '';
                display: block;
                margin: 0 auto;
                height: 2px;
                background: ${color.blue};
                width: 100%;
                right: 0;
                top: 25%;
              }
            }
          }
        }

        .priceMain {
          display: flex;
          justify-content: center;
          align-items: center;

          .forPriceAnimation {
            visibility: hidden;
          }
        }

        .dollarCross,
        .dollarLeftCross {
          display: contents;
          font-weight: 600;
          font-size: 15px;
          @media screen and (min-width: ${breakpoint.md}) {
            font-size: 10px;
          }
          @media screen and (min-width: ${breakpoint.xl}) {
            font-size: 15px;
          }
        }

        .dollar,
        .dollarLeft {
          font-weight: 700;
          font-size: 25px;
        }

        .priceText {
          font-weight: 600;
          font-size: 55px;
          line-height: 55px;

          @media screen and (min-width: ${breakpoint.md}) {
            font-size: 35px;
            line-height: 45px;
          }
          @media screen and (min-width: ${breakpoint.lg}) {
            font-size: 55px;
            line-height: 55px;
          }
        }
        .priceTextFree {
          font-weight: 600;
          font-size: 55px;
          line-height: 55px;

          @media screen and (min-width: ${breakpoint.md}) {
            font-size: 35px;
            line-height: 45px;
          }
          @media screen and (min-width: ${breakpoint.xl}) {
            font-size: 55px;
            line-height: 55px;
          }
        }
      }
      .priceBilledRender {
        visibility: hidden;
      }
    }
  }
`

const Billed = styled.div`
  visibility: hidden;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: ${color.font};
  margin-top: 5px;
  min-height: 25px;

  .lifetime {
    color: ${color.yellow};
    font-weight: 700;
  }
`

const SwitchPeriodWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
`

const PriceSwitchTextWrapper = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  text-align: center;

  .darkText {
    color: ${color.font};
  }

  .blueText {
    color: ${color.blue};
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 5px;
    margin-left: 3px;
    position: relative;
    cursor: pointer;
    :hover > .tooltip {
      visibility: visible;
    }
  }

  .and {
    margin-left: 3px;
    color: ${color.blue};
  }
`

const FreePlanIconTextWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
    margin-top: 25px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    margin-top: 0;
  }
  .content {
    display: flex;
    margin-top: 13px;
    position: relative;
    cursor: pointer;
    width: fit-content;
    :hover > .tooltip {
      visibility: visible;
    }
  }
`

const FreePlanIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  background: #34bffc;
  border-radius: 50%;
  color: ${color.white};
  font-size: 14px;
  margin-right: 10px;
`

const FreePlanIconText = styled.span`
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 5px;
  color: ${color.font};
  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 14px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 16px;
  }
`

const EnterpriseStars = styled.div`
  min-height: 92px;
  margin-top: 25px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.md}) {
    margin-top: 10px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    margin-top: 25px;
  }
`

/* -------------------------------------------------- Card Bottom -------------------------------------------------- */
const BottomWrapper = styled.div``

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11px 0;
    font-size: 14px;
    line-height: 21px;
    border-radius: 8px;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 13px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 14px;
    }
  }

  .blueButton {
    font-weight: bold;
    font-size: 16px;
    background: #0e64e6;
    color: ${color.white};
    transition: ${transition.default};

    &:hover {
      background: #0f50b9;
    }
  }

  .freeButton {
    background: ${color.white};
    font-size: 16px;
    font-weight: bold;
    color: ${color.blue};
    box-shadow: inset 0 0 0 2px ${color.blue};
    transition: ${transition.default};

    &:hover {
      background: ${color.blue};
      color: ${color.white};
    }
  }

  button {
    cursor: pointer;
  }
`

const FeaturesWrapper = styled.div`
  .featureTitle {
    font-weight: bold;
    font-size: 16px;
    color: ${color.font};
    margin: 20px 0;
  }
`

const Feature = styled.div`
  font-weight: 300;
  font-size: 14px;
  margin-top: 10px;
  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 13px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 14px;
  }

  .enabledFeature,
  .disabledFeature {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;

    img {
      margin-right: 8px;
    }
  }

  .enabledFeature {
    position: relative;
    cursor: pointer;

    :hover > .tooltip {
      visibility: visible;
    }

    .aiFeature {
      font-weight: bold;

      .featureName {
        margin: 0 5px;
      }
    }

    .featureText {
      color: ${color.font};

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 25px);
        height: 1px;
        left: 25px;
        bottom: -4px;
        border-top: 1px dashed rgb(147, 161, 176);
      }
    }

    .aIRequestsContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .aiIcon {
        margin: 3px 0 0;
      }
    }
  }

  .disabledFeature {
    .featureText {
      color: #bbc9e4;
    }
  }
`
